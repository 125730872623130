import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createConversation(ctx, conversationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user-notifications/send', conversationData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/users')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchConversations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/conversations/list', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getTwilioToken() {
      return new Promise((resolve, reject) => {
        axios
          .get('/conversations/twilio/token')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getUserTwilioToken(ctx, { sid }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/conversations/twilio/user/' + sid + '/token')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getTwilioConversation(ctx, { sid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/conversations/twilio/detail/${sid}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getConversation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/conversations/detail/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
